import { Link } from 'gatsby';
import React from 'react';
import IconCircle from './IconCircle';
import useLocationUtils from './useLocationUtils';

// eslint-disable-next-line react/prop-types
export default function SoftwareNavLinks({ location }) {
  const { getRouteKey } = useLocationUtils({ location });
  const softwareRoute = getRouteKey();
  return (
    <>
      <Link
        to="/software"
        className={`${softwareRoute === '/' ? 'active' : ''}`}
      >
        <IconCircle size={60} type="home" active={softwareRoute === '/'} />
        <span>Overview</span>
      </Link>
      <Link
        to="/software/inventory"
        className={`${softwareRoute === '/inventory' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="inventory"
          active={softwareRoute === '/inventory'}
        />
        <span>Inventory</span>
      </Link>
      <Link
        to="/software/imagery"
        className={`${softwareRoute === '/imagery' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="imagery"
          active={softwareRoute === '/imagery'}
        />
        <span>Imagery</span>
      </Link>
      <Link
        to="/software/insights"
        className={`${softwareRoute === '/insights' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="insights"
          active={softwareRoute === '/insights'}
        />
        <span>Insights</span>
      </Link>
    </>
  );
}
