import React from 'react';
import { Link } from 'gatsby';
import { Facebook, Instagram, Twitter, Linkedin } from 'react-feather';

export default function CaiFooter() {
  return (
    <footer id="footer" className="container-fluid">
      <div className="row" style={{ justifyContent: 'space-evenly' }}>
        <div className="m-3">
          <h5>
            <Link className="" to="/software#overview">
              Software
            </Link>
          </h5>

          <ul>
            <li>
              <Link to="/software/inventory#overview">Inventory</Link>
            </li>
            <li>
              <Link to="/software/imagery#overview">Imagery</Link>
            </li>
            <li>
              <Link to="/software/insights#overview">Insights</Link>
            </li>
          </ul>
        </div>
        <div className="m-3">
          <h5>
            <Link className="" to="/services#overview">
              Services
            </Link>
          </h5>
          <ul>
            <li>
              <Link to="/services/drone#overview">Drone Capture</Link>
            </li>
            <li>
              <Link to="/services/mapping#overview">GIS (Mapping)</Link>
            </li>
            <li>
              <Link to="/services/integrations#overview">Data Integration</Link>
            </li>
          </ul>
        </div>

        <div className="m-3">
          <h5>
            <Link to="/partners#overview">Partners</Link>
          </h5>
          <ul>
            <li>
              <Link to="/partners#john-deere">John Deere</Link>
            </li>
            <li>
              <Link to="/partners#wingtra">Wingtra</Link>
            </li>
          </ul>
        </div>

        <div className="m-3">
          <h5>
            <Link to="/about#overview">About</Link>
          </h5>
          <ul>
            <li>
              <Link to="/about#our-company">Our Company</Link>
            </li>
            <li>
              <Link to="/about#locations">Locations</Link>
            </li>
            <li>
              <Link component="a" href="mailto:info@cultivateagi.com">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="m-3">
          <h5>
            <Link component="a" href="mailto:info@cultivateagi.com">
              Contact
            </Link>
          </h5>
          <div className="social-links">
            <a href="https://www.facebook.com/CultivateAI/">
              <Facebook />
            </a>
            <a href="https://twitter.com/cultivateagi/">
              <Twitter />
            </a>
            <a href="https://www.instagram.com/cultivateagi/">
              <Instagram />
            </a>
            <a href="https://www.linkedin.com/company/cultivateai">
              <Linkedin />
            </a>
          </div>
        </div>
      </div>
      <div className="row text-center mt-5 d-flex flex-column small">
        <p>
          Full Product Line Brochure Available -{' '}
          <a href="https://cdn.cultivateagi.com/marketing/products/CAI_2Page_English.pdf">
            English
          </a>{' '}
          |{' '}
          <a href="https://cdn.cultivateagi.com/marketing/products/CAI_2Page_SP.pdf">
            Spanish
          </a>
        </p>
        <div className="col">
          Copyright &copy; 2021 - 2023 Cultivate Agricultural Intelligence, LLC.
          All rights reserved.
        </div>
        <div className="mt-2">
          <a href="https://platform.cultivateagi.com/legal/terms-of-service.html">
            Terms of Service
          </a>
          <span className="mx-1">|</span>
          <a href="https://platform.cultivateagi.com/legal/privacy-policy.html">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
