/* eslint-disable react/prop-types */
import React from 'react';
import SoftwareNavLinks from '../components/SoftwareNavLinks';
import ServicesNavLinks from '../components/ServicesNavLinks';
// import { Link } from 'gatsby';
import Transition from '../components/Transition';
// import IconCircle from '../components/IconCircle';
import useLocationUtils from '../components/useLocationUtils';

export default function PageLayout({ location, children }) {
  const { getRouteKey, getMainRouteKey } = useLocationUtils({
    location,
  });
  const mainRouteKey = getMainRouteKey();
  const routeKey = getRouteKey();
  // const subtitle = getSubtitle();
  return (
    <div
      className="container-fluid position-relative"
      style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      {mainRouteKey !== '/' ? (
        <div id="overview" className={`${mainRouteKey} page-banner row`}>
          <div className="bg-div" />
          <div className="page-header">
            <h1 className="col-12 heading">
              {mainRouteKey === 'about' ? 'ABOUT US' : mainRouteKey}
              {/* <Link to="/software">Software</Link> */}
            </h1>
            {/* {subtitle ? (
                  <h5 className="col-12 col-sm-6 mt-1 page-subheader">
                    {subtitle}
                  </h5>
                ) : null} */}
            <div className="sub-nav-links">
              {mainRouteKey === 'software' ? (
                <SoftwareNavLinks location={location} />
              ) : null}
              {mainRouteKey === 'services' ? (
                <ServicesNavLinks location={location} />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <Transition
        className="transition-container"
        transitionKey={routeKey}
        timeout={200}
      >
        {children}
      </Transition>
    </div>
  );
}
