import { Link } from 'gatsby';
import React from 'react';
import IconCircle from './IconCircle';
import useLocationUtils from './useLocationUtils';

// eslint-disable-next-line react/prop-types
export default function ServicesNavLinks({ location }) {
  const { getRouteKey } = useLocationUtils({ location });
  const servicesRoute = getRouteKey();
  return (
    <>
      <Link
        to="/services"
        className={`${servicesRoute === '/' ? 'active' : ''}`}
      >
        <IconCircle size={60} type="home" active={servicesRoute === '/'} />
        <span className="sub-nav-link-text">Overview</span>
      </Link>
      <Link
        to="/services/drone"
        className={`${servicesRoute === '/drone' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="drone"
          active={servicesRoute === '/drone'}
        />
        <span className="sub-nav-link-text">Drone Capture</span>
      </Link>
      <Link
        to="/services/mapping"
        className={`${servicesRoute === '/mapping' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="target"
          active={servicesRoute === '/mapping'}
        />
        <span className="sub-nav-link-text">GIS Mapping</span>
      </Link>
      <Link
        to="/services/integrations"
        className={`${servicesRoute === '/integrations' ? 'active' : ''}`}
      >
        <IconCircle
          size={60}
          type="insights"
          active={servicesRoute === '/integrations'}
        />
        <span className="sub-nav-link-text">Data Integration</span>
      </Link>
    </>
  );
}
