// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-services-drone-index-js": () => import("./../../../src/pages/services/drone/index.js" /* webpackChunkName: "component---src-pages-services-drone-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-integrations-index-js": () => import("./../../../src/pages/services/integrations/index.js" /* webpackChunkName: "component---src-pages-services-integrations-index-js" */),
  "component---src-pages-services-mapping-index-js": () => import("./../../../src/pages/services/mapping/index.js" /* webpackChunkName: "component---src-pages-services-mapping-index-js" */),
  "component---src-pages-software-imagery-index-js": () => import("./../../../src/pages/software/imagery/index.js" /* webpackChunkName: "component---src-pages-software-imagery-index-js" */),
  "component---src-pages-software-index-js": () => import("./../../../src/pages/software/index.js" /* webpackChunkName: "component---src-pages-software-index-js" */),
  "component---src-pages-software-insights-index-js": () => import("./../../../src/pages/software/insights/index.js" /* webpackChunkName: "component---src-pages-software-insights-index-js" */),
  "component---src-pages-software-inventory-index-js": () => import("./../../../src/pages/software/inventory/index.js" /* webpackChunkName: "component---src-pages-software-inventory-index-js" */)
}

