/* eslint-disable react/prop-types */
import React from 'react';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group';

// The duration for each phase of the transition
// So the total duration will be _twice_ this
const timeout = 300;

// const commonStyles = {
// display: 'flex',
// flexDirection: 'column',
// flex: 1,
// };

// const styles = {
//   entering: {
//     // ...commonStyles,
//     position: 'absolute',
//     opacity: 0,
//   },
//   entered: {
//     // ...commonStyles,
//     transition: `all ${timeout}ms ease-in-out`,
//     opacity: 1,
//   },
//   exiting: {
//     // ...commonStyles,
//     transition: `all ${timeout}ms ease-in-out`,
//     opacity: 0,
//   },
// };

const Transition = ({
  children,
  transitionKey,
  commonStyles,
  className,
  // enteringStyles,
  // enteredStyles,
  // exitingStyles,
}) => {
  const styles = {
    entering: {
      position: 'absolute',
      opacity: 0,
      // ...enteringStyles,
      flex: 0,
      height: 0,
    },
    entered: {
      transition: `all ${timeout}ms ease-in-out`,
      opacity: 1,
      // ...enteredStyles,
      flex: 1,
      height: '100%',
    },
    exiting: {
      transition: `all ${timeout}ms ease-in-out`,
      opacity: 0,
      flex: 0,
      height: 0,
      // ...exitingStyles,
    },
  };

  return (
    <TransitionGroup
      className={`row${className ? ` ${className}` : ''}`}
      style={{
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <ReactTransition key={transitionKey} timeout={timeout}>
        {status => (
          <div
            style={{
              ...styles[status],
              ...commonStyles,
              ...{ overflow: 'hidden', width: '100%' },
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

export default Transition;
