// import React from 'react';

export default function useLocationUtils({ location }) {
  function getMainRouteKey() {
    if (!location) {
      return null;
    }
    if (location.pathname === '/' || location.pathname.includes('/software')) {
      return 'software';
    }
    if (location.pathname.includes('/services')) {
      return 'services';
    }
    if (location.pathname.includes('/about')) {
      return 'about';
    }
    if (location.pathname.includes('/partners')) {
      return 'partners';
    }
    return location.pathname;
  }

  function getRouteKey() {
    const mainKey = getMainRouteKey();
    if (mainKey === 'software') {
      const isImagery = location.pathname.includes('imagery');
      const isInventory = location.pathname.includes('inventory');
      const isInsights = location.pathname.includes('insights');
      if (isImagery) {
        return '/imagery';
      }
      if (isInventory) {
        return '/inventory';
      }
      if (isInsights) {
        return '/insights';
      }
      return '/';
    }
    if (mainKey === 'services') {
      const isDroneCapture = location.pathname.includes('drone');
      const isGisMapping = location.pathname.includes('mapping');
      const isDataIntegration = location.pathname.includes('integrations');
      if (isDroneCapture) {
        return '/drone';
      }
      if (isGisMapping) {
        return '/mapping';
      }
      if (isDataIntegration) {
        return '/integrations';
      }
      return '/';
    }
    // if (mainKey === 'about') {
    //   return '/about/main';
    // }
    return mainKey;
  }

  // function getSubtitle() {
  //   const mainKey = getMainRouteKey();
  //   const key = getRouteKey();
  //   if (mainKey === 'software') {
  //     if (key === '/inventory') {
  //       return 'YOUR RECORDS. ONE PLACE.';
  //     }
  //     if (key === '/imagery') {
  //       return 'VISUAL IMAGERY INTO VALUABLE INSIGHTS';
  //     }
  //     if (key === '/insights') {
  //       return 'SIMPLE SOFTWARE. TRUSTED ANSWERS. IMPROVED RESULTS.';
  //     }
  //     return 'COMPLEX DATA. SIMPLIFIED SOLUTIONS. ';
  //   }
  //   if (mainKey === 'services') {
  //     if (key === '/drone') {
  //       return 'HIDDEN PLANT HEALTH. REVEALED.';
  //     }
  //     if (key === '/mapping') {
  //       return 'DIGITAL TRANSFORMATION. NO ADDED WORK.';
  //     }
  //     if (key === '/integrations') {
  //       return 'All YOUR DATA SOURCES. UNIFIED.';
  //     }
  //     return 'YOUR DIGITAL TRANSFORMATION. DELIVERED.';
  //   }
  //   if (mainKey === 'about') {
  //     return 'Accurate. Simple. Trusted.';
  //   }
  //   return '';
  // }

  return { getMainRouteKey, getRouteKey };
}
