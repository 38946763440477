/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Link } from 'gatsby';
import '@cultivate-ai/styleguide-react-components/dist/cai-core.css';
import '@cultivate-ai/styleguide-react-components/dist/cai-react-components.css';
import { Navbar, Nav } from 'react-bootstrap';
import '../index.scss';
import CaiFooter from '../components/Footer';
import useLocationUtils from '../components/useLocationUtils';
import MainNavLinks from '../components/MainNavLinks';
import PageLayout from './page-layout';
import pkgJson from '../../package.json';

export default function Layout({ location, children }) {
  const containerRef = useRef();
  const { getMainRouteKey } = useLocationUtils({ location });

  const transitionKey = getMainRouteKey();
  return (
    <>
      <div className="d-none">{`v${pkgJson.version}`}</div>
      <Navbar variant="dark" expand="sm">
        <Navbar.Brand to="/#landing" as={Link} />
        <Navbar.Toggle aria-controls="cai-navbar-nav" />
        <Navbar.Collapse id="cai-navbar-nav">
          <Nav className="ml-auto header-nav">
            <Link
              className={`nav-link${
                location.pathname.includes('/software') ||
                location.pathname === '/'
                  ? ' active'
                  : ''
              }`}
              to="/software/#nav-links"
            >
              Software
            </Link>
            <Link
              className={`nav-link${
                location.pathname.includes('/services') ? ' active' : ''
              }`}
              to="/services#nav-links"
            >
              Services
            </Link>
            <Link
              className={`nav-link${
                location.pathname.includes('/partners') ? ' active' : ''
              }`}
              to="/partners#nav-links"
            >
              Partners
            </Link>
            <Link
              className={`nav-link${
                location.pathname.includes('/about') ? ' active' : ''
              }`}
              to="/about#nav-links"
            >
              About
            </Link>
            <a
              href="https://www.agassessor.com"
              className="btn btn-sm btn-outline-light nav-link"
              style={{ padding: '0.25rem' }}
            >
              Sign In
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div
        className="overflow-auto h-100"
        ref={containerRef}
        style={{ scrollBehavior: 'smooth' }}
      >
        <div id="landing" className="h-100 overflow-auto">
          <div id="home" className="main container-fluid h-100">
            <div className="d-flex flex-column h-100 p-0" style={{ flex: 1 }}>
              <>
                <div className="main-hero row">
                  <div className="col-12 d-flex flex-column">
                    <div
                      className="heading-main mr-auto"
                      // style={{ marginLeft: '15%' }}
                    >
                      <h1 className="main-heading d-flex flex-column text-uppercase">
                        <span style={{ fontWeight: 700 }}>Transform</span>
                        <span
                          style={{
                            // position: 'relative',
                            // top: '-13px',
                            fontWeight: 100,
                            fontSize: '80%',
                          }}
                        >
                          {' '}
                          the way you{' '}
                        </span>
                        <span
                          className="text-warning"
                          style={{
                            fontWeight: 700,
                            // position: 'relative',
                            // top: '-25px',
                          }}
                        >
                          Grow
                        </span>
                      </h1>
                      <div className="row">
                        <div className="col-md-5">
                          <p style={{ fontSize: '1rem' }}>
                            CultivateAI is a cloud-based, mobile platform that
                            helps you make informed, data-driven decisions with
                            real-time analytics. Our trusted insights are
                            designed to help your Ag Operation increase
                            production, manage risk, and maximize profitability.
                          </p>
                          <div className="">
                            <a
                              href="https://www.agassessor.com"
                              className="btn btn-outline-light text-uppercase"
                            >
                              Sign In
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <MainNavLinks routeKey={transitionKey} />
              </>
            </div>
          </div>
        </div>
        <div
          id="main"
          style={transitionKey === '/' ? { minHeight: '0' } : null}
        >
          <PageLayout location={location}>{children}</PageLayout>
        </div>
        <CaiFooter />
      </div>
    </>
  );
}
