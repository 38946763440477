import React from 'react';
// import colors from '@cultivate-ai/styleguide-react-components/src/core/custom-bootstrap/_bootstrap_variables.scss';

// eslint-disable-next-line react/prop-types
export default function IconCircle({ gradient, active, type, size = 64 }) {
  let fill = '#fff';
  if (gradient) {
    fill = 'url(#blue-gradient)';
  }
  if (active) {
    fill = '#d5e100';
  }
  return (
    <svg
      version="1.1"
      height={`${size}px`}
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 144 144"
      xmlSpace="preserve"
    >
      <linearGradient
        id="blue-gradient"
        gradientUnits="userSpaceOnUse"
        x1="30.5096"
        y1="72.8834"
        x2="113.0816"
        y2="72.8834"
      >
        <stop offset="0" style={{ stopColor: '#00FFFF' }} />
        <stop offset="1" style={{ stopColor: '#0071C1' }} />
      </linearGradient>
      {type === 'inventory' ? (
        <>
          <path
            fill={fill}
            d="M72.67,128c-30.33,0-55-24.67-55-55s24.67-55,55-55s55,24.67,55,55S102.99,128,72.67,128z M72.67,22.36
        c-27.93,0-50.64,22.72-50.64,50.64s22.72,50.64,50.64,50.64s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
          />
          <path
            fill={fill}
            d="M71.5,55.98c27.96,7.78,31.42,22.13,32.87,38.47C98.35,77.27,84.53,67.2,63.79,64.3
        c24.87,7.83,36.62,24.33,41.18,39.81c-0.67,0.06-2.89-7.92-15.15-12.35c-7.5-2.71-28.74-2.7-40.66-16.14
        c-11.71-13.21-6.12-34.54-6.27-34.58C42.9,40.79,44.11,48.36,71.5,55.98z"
          />
        </>
      ) : null}
      {type === 'insights' ? (
        <>
          <path
            fill={fill}
            d="M72.67,128c-30.33,0-55-24.67-55-55s24.67-55,55-55s55,24.67,55,55S102.99,128,72.67,128z M72.67,22.36
	c-27.93,0-50.64,22.72-50.64,50.64s22.72,50.64,50.64,50.64s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
          />
          <path
            fill={fill}
            d="M74.64,82.46c3.79,0,6.99-2.52,7.81-6.21c0.19-0.87,0.26-1.78,0.34-2.68c0.54-6.31,4.91-11.42,11.08-12.84
	c1.19-0.27,2.43-0.3,3.64-0.45c3.3-0.4,5.67-2.11,6.84-5.22c1.17-3.12,0.54-6.01-1.78-8.4c-2.31-2.39-5.19-3.09-8.34-2.03
	c-3.71,1.25-5.39,4.7-5.51,7.93c-0.27,7.53-6.39,13.56-13.71,13.75c-7.35,0.19-13.62-5.48-14.41-13.03c-0.09-0.9-0.11-1.8-0.28-2.69
	c-0.74-3.88-4.56-6.77-8.42-6.42c-4.16,0.38-7.38,3.72-7.47,7.77c-0.11,4.68,3.21,8.11,8.09,8.37c5.41,0.29,9.49,2.78,12.23,7.45
	c1.27,2.17,1.74,4.55,1.88,7.04C66.86,79.22,70.3,82.46,74.64,82.46z M44.43,75.71c0.04,3.97,2.88,7.21,6.81,7.79
	c0.81,0.12,1.63,0.21,2.44,0.27c3.06,0.24,5.68,1.48,7.95,3.48c3.25,2.86,4.79,6.51,4.98,10.83c0.19,4.32,3.48,7.55,7.71,7.77
	c4,0.21,7.57-2.76,8.26-6.91c0.12-0.72,0.18-1.46,0.22-2.2c0.17-2.7,1.13-5.11,2.75-7.24c2.67-3.51,6.21-5.43,10.64-5.84
	c1.52-0.14,3.13-0.41,4.47-1.07c3.43-1.7,4.96-5.73,3.83-9.41c-1.14-3.72-4.72-5.99-8.78-5.55c-3.59,0.38-6.55,3.48-6.94,7.21
	c-0.14,1.38-0.19,2.79-0.54,4.12c-1.68,6.54-7.79,10.97-14.16,10.71c-6.89-0.27-12.66-5.85-13.44-12.75
	c-0.09-0.81-0.13-1.63-0.25-2.44c-0.66-4.45-4.36-7.28-8.94-6.86C47.5,67.98,44.38,71.56,44.43,75.71z"
          />
        </>
      ) : null}
      {type === 'imagery' ? (
        <>
          <path
            fill={fill}
            d="M72.67,128c-30.33,0-55-24.67-55-55s24.67-55,55-55s55,24.67,55,55S102.99,128,72.67,128z M72.67,22.36
	c-27.93,0-50.64,22.72-50.64,50.64s22.72,50.64,50.64,50.64s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
          />
          <g>
            <g>
              <path
                id="Shape_1_"
                fill={fill}
                d="M42.73,91.43v2.14c0,0.59,0.48,1.07,1.07,1.07h49.24c1.77,0,3.21-1.44,3.21-3.21V53.96h-4.28
			v34.79c0,0.89-0.72,1.61-1.61,1.61H43.8C43.21,90.36,42.73,90.83,42.73,91.43z"
              />
              <path
                id="Shape_2_"
                fill={fill}
                d="M52.36,101.06c0.59,0,1.07-0.48,1.07-1.07v-3.21h-4.28v3.21c0,0.59,0.48,1.07,1.07,1.07H52.36z
			"
              />
              <path
                id="Shape_3_"
                fill={fill}
                d="M95.18,41.11h-2.14c-0.59,0-1.07,0.48-1.07,1.07v3.21h4.28v-3.21
			C96.25,41.59,95.78,41.11,95.18,41.11z"
              />
              <path
                id="Shape_4_"
                fill={fill}
                d="M101.61,47.53H52.36c-1.77,0-3.21,1.44-3.21,3.21v37.47h4.28v-0.84c-0.06-0.2-0.06-0.41,0-0.61
			V53.42c0-0.89,0.72-1.61,1.61-1.61h46.57c0.59,0,1.07-0.48,1.07-1.07V48.6C102.68,48.01,102.2,47.53,101.61,47.53z"
              />
            </g>
            <g>
              <path
                fill={fill}
                d="M61.93,86.07c-1.07,0-1.93-0.8-1.93-1.78V58.87c0-0.98,0.86-1.78,1.93-1.78s1.93,0.8,1.93,1.78V84.3
			C63.86,85.28,63,86.07,61.93,86.07z"
              />
              <path
                fill={fill}
                d="M72.55,86.07c-1.07,0-1.93-0.8-1.93-1.78V58.87c0-0.98,0.86-1.78,1.93-1.78c1.07,0,1.93,0.8,1.93,1.78V84.3
			C74.48,85.28,73.61,86.07,72.55,86.07z"
              />
              <path
                fill={fill}
                d="M83.17,86.07c-1.07,0-1.93-0.8-1.93-1.78V58.87c0-0.98,0.86-1.78,1.93-1.78s1.93,0.8,1.93,1.78V84.3
			C85.1,85.28,84.23,86.07,83.17,86.07z"
              />
            </g>
          </g>
        </>
      ) : null}
      {type === 'home' ? (
        <g>
          <g>
            <path
              fill={fill}
              d="M55.47,69.3c-12.02-3.22-13.59-9.47-14.31-16.61c2.68,7.47,8.66,11.78,17.56,12.92
            c-10.7-3.27-15.83-10.4-17.88-17.14c0.28-0.03,1.29,3.44,6.56,5.3c3.23,1.14,12.32,1,17.51,6.8c5.09,5.7,2.84,15.06,2.9,15.08
            C67.81,75.76,67.24,72.46,55.47,69.3z"
            />
            <g>
              <path
                fill={fill}
                d="M104.17,70.77c-0.6-1.47-1.73-2.29-3.29-2.37c-1.83-0.1-3.16,1.14-3.77,2.57c-0.54,1.27-1.43,2.29-2.49,3
              c0.5-1.03,0.99-2.08,1.39-3.18c0.12-0.32,0.24-0.65,0.37-0.98c0.59-1.54,1.2-3.13,1.38-4.8c0.62-5.59-0.9-10.3-4.52-14.02
              c-1.25-1.29-2.67-2.75-4.39-3.64c-2.36-1.22-8.03-4.41-18.32-3.93c-1.93,0.09-3.79,0.91-5.44,1.59c-3.18,1.32-5.67,3.43-7.44,6.3
              c3.48,1.26,5.82,2.91,7.35,4.38c1.05-1.57,2.38-2.64,4.08-3.27l0,0c0,0,1.86-1.06,7.9-0.5c3.33,0.28,7.43,2.58,8.5,3.29
              c0.3,0.18,0.56,0.37,0.76,0.56c0.02,0.02,0.04,0.03,0.06,0.05c2.14,1.81,3.15,4.16,3.15,7.39c0.01,2.07-0.87,3.87-1.81,5.77
              l-0.3,0.62c-0.48,0.98-0.96,1.97-1.45,2.96c-1.28-1.68-1.79-3.97-1.17-6.2c0.11-0.42,0.26-0.82,0.34-1.25
              c0.35-1.86-0.82-3.82-2.57-4.34c-1.88-0.56-3.86,0.36-4.6,2.15c-0.85,2.07,0,4.18,2.09,5.15c2.31,1.08,3.66,2.91,4.05,5.47
              c0.18,1.19-0.02,2.33-0.39,3.47c-0.02,0.05-0.03,0.1-0.04,0.15c-0.3,0.6-0.61,1.21-0.9,1.81c-0.49,1-0.97,2-1.45,3.01
              c-0.79-1.52-1.04-3.34-0.58-5.07c0.1-0.38,0.22-0.75,0.31-1.13c0.48-2.1-0.65-4.01-2.72-4.63c-1.8-0.54-3.77,0.51-4.47,2.37
              c-0.66,1.77,0.01,3.72,1.63,4.67c0.33,0.2,0.67,0.38,1.02,0.55c1.29,0.64,2.23,1.66,2.87,2.95c0.46,0.91,0.7,1.83,0.74,2.77
              c-0.15,0.3-0.29,0.61-0.44,0.91c-0.52,1.06-1.05,2.12-1.58,3.18l-0.7,1.39c-0.19,0.39-0.39,0.78-0.58,1.18
              c-0.44,0.9-0.89,1.83-1.37,2.71c-0.04,0.08-0.25,0.21-0.57,0.24c-0.29,0.02-0.5-0.06-0.54-0.11c-0.61-0.78-1.23-1.67-1.9-2.72
              c-0.36-0.56-0.71-1.15-1.05-1.71c-0.38-0.63-0.77-1.28-1.18-1.92c-0.78-1.2-1.59-2.4-2.37-3.56c-0.55-0.82-1.1-1.64-1.65-2.46
              c-0.32-0.48-1.77-2.61-2.99-4.36c-0.04-0.02-0.11-0.13-0.21-0.3c-0.35-0.5-0.68-0.96-0.94-1.32c-0.71-0.8-1.85-1.75-3.7-2.43
              c-1.73-0.64-5.74-0.83-9.28-2.51c0.83,1.36,4.66,7.62,6.01,9.58c1.28,1.85,2.49,3.6,2.95,4.31l0.05,0.07
              c1.64,2.51,3.27,5.02,4.9,7.53l0.49,0.76c1.11,1.72,2.27,3.51,3.36,5.28c1.18,1.9,2.87,3.26,4.89,3.92
              c1.16,0.38,2.3,0.57,3.38,0.57c3.25,0,6.07-1.69,7.98-4.88c0.93-1.54,1.66-3.21,2.36-4.82c0.32-0.72,0.64-1.47,0.98-2.18
              l0.05-0.1c0.08-0.18,0.17-0.35,0.25-0.53c0.09-0.17,0.17-0.34,0.25-0.51c0.54-1.17,1.37-2.08,2.45-2.74
              c1.77-1.1,3.65-1.33,5.65-0.73c0.69,0.21,1.44,0.37,2.14,0.31c1.79-0.15,3.15-1.68,3.29-3.52c0.14-1.86-1.03-3.5-2.87-4.02
              c-1.63-0.46-3.46,0.4-4.27,1.99c-0.3,0.59-0.56,1.21-0.94,1.74c-0.83,1.17-1.98,2-3.24,2.44c0.37-0.74,0.74-1.49,1.11-2.24
              c0.29-0.58,0.56-1.17,0.84-1.76c0.35-0.73,0.69-1.46,1.05-2.19c0.28-0.57,0.58-1.13,0.87-1.69c0.13-0.24,0.26-0.49,0.39-0.74
              c1.32-0.85,2.93-1.22,4.58-0.96c0.56,0.09,1.11,0.29,1.67,0.44c1.51,0.4,2.84,0.06,3.88-1.12
              C104.54,73.64,104.76,72.24,104.17,70.77z"
              />
              <path
                fill={fill}
                d="M72.59,43.36c9,0,14.07,2.84,16.26,3.98c1.72,0.89,3.14,2.35,4.39,3.64c3.62,3.72,5.14,8.44,4.52,14.02
              c-0.19,1.67-0.79,3.26-1.38,4.8c-0.12,0.33-0.25,0.65-0.37,0.98c-0.4,1.09-0.9,2.15-1.39,3.18c1.06-0.71,1.95-1.72,2.49-3
              c0.58-1.37,1.84-2.57,3.57-2.57c0.07,0,0.13,0,0.2,0.01c1.56,0.08,2.69,0.9,3.29,2.37c0.6,1.47,0.37,2.87-0.67,4.06
              c-0.77,0.87-1.69,1.29-2.72,1.29c-0.37,0-0.76-0.05-1.16-0.16c-0.56-0.15-1.1-0.35-1.67-0.44c-0.35-0.05-0.69-0.08-1.03-0.08
              c-1.28,0-2.5,0.37-3.55,1.04c-0.13,0.25-0.26,0.5-0.39,0.74c-0.29,0.56-0.59,1.12-0.87,1.69c-0.36,0.73-0.71,1.46-1.05,2.19
              c-0.28,0.59-0.56,1.18-0.84,1.76c-0.36,0.75-0.74,1.49-1.11,2.24c1.26-0.45,2.41-1.27,3.24-2.44c0.38-0.53,0.64-1.15,0.94-1.74
              c0.66-1.3,2.01-2.12,3.37-2.12c0.3,0,0.61,0.04,0.9,0.12c1.85,0.52,3.02,2.16,2.87,4.02c-0.14,1.84-1.5,3.36-3.29,3.52
              c-0.1,0.01-0.2,0.01-0.3,0.01c-0.61,0-1.25-0.15-1.83-0.32c-0.72-0.21-1.42-0.32-2.1-0.32c-1.23,0-2.41,0.35-3.55,1.05
              c-1.07,0.66-1.91,1.57-2.45,2.74c-0.08,0.17-0.16,0.34-0.25,0.51c-0.08,0.18-0.17,0.35-0.25,0.53l-0.05,0.1
              c-0.34,0.72-0.66,1.46-0.98,2.18c-0.71,1.61-1.43,3.28-2.36,4.82c-1.92,3.2-4.73,4.88-7.98,4.88c-1.08,0-2.21-0.19-3.38-0.57
              c-2.02-0.66-3.71-2.01-4.89-3.92c-1.1-1.77-2.25-3.55-3.36-5.28l-0.49-0.76c-1.63-2.52-3.26-5.02-4.9-7.53l-0.05-0.07
              c-0.46-0.71-1.67-2.46-2.95-4.31c-1.36-1.96-5.19-8.23-6.01-9.58c3.55,1.68,7.55,1.87,9.28,2.51c1.86,0.68,2.99,1.63,3.7,2.43
              c0.26,0.36,0.59,0.82,0.94,1.32c0.1,0.17,0.17,0.27,0.21,0.3c1.22,1.74,2.67,3.87,2.99,4.36c0.55,0.82,1.1,1.64,1.65,2.46
              c0.78,1.16,1.59,2.37,2.37,3.56c0.41,0.63,0.8,1.29,1.18,1.92c0.34,0.57,0.69,1.15,1.05,1.71c0.67,1.05,1.3,1.94,1.9,2.72
              c0.03,0.04,0.2,0.12,0.44,0.12c0.03,0,0.06,0,0.1,0c0.31-0.03,0.52-0.16,0.57-0.24c0.48-0.88,0.93-1.81,1.37-2.71
              c0.19-0.39,0.38-0.79,0.58-1.18l0.7-1.39c0.53-1.06,1.06-2.12,1.58-3.18c0.15-0.3,0.29-0.61,0.44-0.91
              c-0.05-0.94-0.29-1.86-0.74-2.77c-0.65-1.29-1.58-2.31-2.87-2.95c-0.35-0.17-0.69-0.36-1.02-0.55c-1.62-0.95-2.29-2.89-1.63-4.67
              c0.57-1.53,2.01-2.51,3.5-2.51c0.32,0,0.64,0.05,0.96,0.14c2.07,0.62,3.2,2.53,2.72,4.63c-0.09,0.38-0.21,0.75-0.31,1.13
              c-0.46,1.74-0.21,3.55,0.58,5.07c0.48-1,0.96-2.01,1.45-3.01c0.3-0.61,0.6-1.21,0.9-1.81c0.01-0.05,0.02-0.1,0.04-0.15
              c0.37-1.14,0.58-2.28,0.39-3.47c-0.39-2.56-1.74-4.39-4.05-5.47c-2.09-0.98-2.94-3.09-2.09-5.15c0.59-1.43,1.98-2.31,3.48-2.31
              c0.37,0,0.74,0.05,1.12,0.16c1.75,0.52,2.91,2.48,2.57,4.34c-0.08,0.42-0.23,0.83-0.34,1.25c-0.61,2.24-0.11,4.53,1.17,6.2
              c0.49-0.98,0.98-1.97,1.45-2.96l0.3-0.62c0.93-1.9,1.81-3.7,1.81-5.77c-0.01-3.23-1.01-5.58-3.15-7.39
              c-0.02-0.02-0.04-0.03-0.06-0.05c-0.2-0.19-0.45-0.37-0.76-0.56c-1.07-0.71-5.17-3.01-8.5-3.29c-1.26-0.12-2.34-0.16-3.26-0.16
              c-3.47,0-4.64,0.66-4.64,0.66l0,0c-1.7,0.63-3.03,1.7-4.08,3.27c-1.53-1.47-3.87-3.11-7.35-4.38c1.77-2.86,4.26-4.98,7.44-6.3
              c1.65-0.68,3.51-1.5,5.44-1.59C71.23,43.37,71.92,43.36,72.59,43.36"
              />
            </g>
          </g>
          <path
            fill={fill}
            d="M72.67,128c-30.33,0-55-24.67-55-55c0-30.33,24.67-55,55-55s55,24.67,55,55
          C127.67,103.33,102.99,128,72.67,128z M72.67,22.36c-27.93,0-50.64,22.72-50.64,50.64c0,27.92,22.72,50.64,50.64,50.64
          s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
          />
        </g>
      ) : null}
      {type === 'drone' ? (
        <g>
          <g>
            <path
              fill={fill}
              d="M72.67,128c-30.33,0-55-24.67-55-55s24.67-55,55-55s55,24.67,55,55S102.99,128,72.67,128z M72.67,22.36
        c-27.93,0-50.64,22.72-50.64,50.64s22.72,50.64,50.64,50.64s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
            />
            <path
              fill={fill}
              d="M96.2,83.3c-2.8,0-5.3,0.9-7.4,2.4l-1.9-1.9c-4.8-6.6-4.8-16.1,0-22.7l1.9-1.9c2.1,1.5,4.6,2.4,7.4,2.4
            c7.1,0,12.8-5.8,12.8-12.8S103.2,36,96.2,36s-12.8,5.8-12.8,12.8c0,2.8,0.9,5.3,2.4,7.4l-1.9,1.9c-6.6,4.8-16.1,4.8-22.7,0
            l-1.9-1.9c1.5-2.1,2.4-4.6,2.4-7.4c0-7.1-5.8-12.8-12.8-12.8S36,41.8,36,48.8s5.8,12.8,12.8,12.8c2.8,0,5.3-0.9,7.4-2.4l1.9,1.9
            c4.8,6.6,4.8,16.1,0,22.7l-1.9,1.9c-2.1-1.5-4.6-2.4-7.4-2.4c-7.1,0-12.8,5.8-12.8,12.8S41.8,109,48.8,109s12.8-5.8,12.8-12.8
            c0-2.8-0.9-5.3-2.4-7.4l1.9-1.9c6.6-4.8,16.1-4.8,22.7,0l1.9,1.9c-1.5,2.1-2.4,4.6-2.4,7.4c0,7.1,5.8,12.8,12.8,12.8
            s12.8-5.8,12.8-12.8S103.2,83.3,96.2,83.3z M96.2,40.3c4.7,0,8.6,3.8,8.6,8.6s-3.8,8.6-8.6,8.6c-1.6,0-3-0.5-4.3-1.2l4.3-4.3
            l0.6,0.6c0.8,0.8,2.2,0.8,3,0s0.8-2.2,0-3l-4.3-4.3c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3l0.6,0.6l-4.3,4.3
            c-0.8-1.3-1.2-2.7-1.2-4.3C87.6,44.1,91.4,40.3,96.2,40.3z M56.2,53.2l-4.3-4.3l0.6-0.6c0.8-0.8,0.8-2.2,0-3s-2.2-0.8-3,0
            l-4.3,4.3c-0.8,0.8-0.8,2.2,0,3s2.2,0.8,3,0l0.6-0.6l4.3,4.3c-1.3,0.8-2.7,1.2-4.3,1.2c-4.7,0-8.6-3.8-8.6-8.6s3.8-8.6,8.6-8.6
            s8.6,3.8,8.6,8.6C57.4,50.4,56.9,51.9,56.2,53.2z M48.8,104.7c-4.7,0-8.6-3.8-8.6-8.6s3.8-8.6,8.6-8.6c1.6,0,3,0.5,4.3,1.2
            l-4.3,4.3l-0.6-0.6c-0.8-0.8-2.2-0.8-3,0s-0.8,2.2,0,3l4.3,4.3c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3l-0.6-0.6l4.3-4.3
            c0.8,1.3,1.2,2.7,1.2,4.3C57.4,100.9,53.6,104.7,48.8,104.7z M96.2,104.7c-4.7,0-8.6-3.8-8.6-8.6c0-1.6,0.5-3,1.2-4.3l4.3,4.3
            l-0.6,0.6c-0.8,0.8-0.8,2.2,0,3c0.4,0.4,1,0.6,1.5,0.6s1.1-0.2,1.5-0.6l4.3-4.3c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0
            l-0.6,0.6l-4.3-4.3c1.3-0.8,2.7-1.2,4.3-1.2c4.7,0,8.6,3.8,8.6,8.6S100.9,104.7,96.2,104.7z"
            />
          </g>
        </g>
      ) : null}
      {type === 'target' ? (
        <g>
          <g>
            <g>
              <path
                fill={fill}
                d="M72.67,128c-30.33,0-55-24.67-55-55s24.67-55,55-55s55,24.67,55,55S102.99,128,72.67,128z M72.67,22.36
        c-27.93,0-50.64,22.72-50.64,50.64s22.72,50.64,50.64,50.64s50.64-22.72,50.64-50.64C123.31,45.07,100.59,22.36,72.67,22.36z"
              />
              <path
                fill={fill}
                d="M71,56.6c-8.5,0-15.4,6.9-15.4,15.4S62.5,87.4,71,87.4S86.4,80.5,86.4,72S79.5,56.6,71,56.6z M71,82.2
              c-5.7,0-10.2-4.6-10.2-10.2c0-5.7,4.6-10.2,10.2-10.2S81.2,66.3,81.2,72S76.7,82.2,71,82.2z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={fill}
                d="M109.4,69.4h-7.8c-1.2-14.9-13.1-26.8-28.1-28.1v-7.8c0-1.4-1.1-2.6-2.6-2.6s-2.6,1.1-2.6,2.6v7.8
              c-14.9,1.2-26.8,13.2-28.1,28.1h-7.8c-1.4,0-2.6,1.1-2.6,2.6s1.1,2.6,2.6,2.6h7.8c1.2,14.9,13.1,26.8,28.1,28.1v7.8
              c0,1.4,1.1,2.6,2.6,2.6s2.6-1.1,2.6-2.6v-7.8c14.9-1.2,26.8-13.1,28.1-28.1h7.8c1.4,0,2.6-1.1,2.6-2.6
              C112,70.6,110.9,69.4,109.4,69.4z M71,97.6c-14.1,0-25.6-11.5-25.6-25.6S56.9,46.4,71,46.4S96.6,57.9,96.6,72S85.1,97.6,71,97.6z
              "
              />
            </g>
          </g>
        </g>
      ) : null}
    </svg>
  );
}
