import React from 'react';
import { Link } from 'gatsby';

function getLinkClasses(activeKey, linkKey) {
  const base = 'd-flex flex-column align-items-center';
  if (activeKey === linkKey) {
    return `${base} active`;
  }
  return base;
}

// eslint-disable-next-line react/prop-types
export default function MainNavLinks({ routeKey }) {
  return (
    <>
      <div
        className="row position-relative"
        style={{ backgroundColor: '#333' }}
      >
        <div id="nav-links" style={{ position: 'absolute', top: '-1rem' }} />
        <div className="col-12 p-0">
          <ul className="link-banner list-group list-group-horizontal mx-sm-1 mx-md-5">
            {/* <li className="">
          <a href="/solutions">
            <div className="solutions link-img" />
            Industry Solution
          </a>
        </li> */}
            <li className="">
              <Link
                to="/software#nav-links"
                className={getLinkClasses(routeKey, 'software')}
              >
                <div className="software link-img" />
                <span className="mt-2">Software</span>
              </Link>
            </li>
            <li className="">
              <Link
                to="/services#nav-links"
                className={getLinkClasses(routeKey, 'services')}
              >
                <div className="services link-img" />
                <span className="mt-2">Services</span>
              </Link>
            </li>
            <li className="">
              <Link
                to="/partners#nav-links"
                className={getLinkClasses(routeKey, 'partners')}
              >
                <div className="partners link-img" />
                <span className="mt-2">Partners</span>
              </Link>
            </li>
            <li className="">
              <Link
                to="/about#nav-links"
                className={getLinkClasses(routeKey, 'about')}
              >
                <div className="about link-img" />
                <span className="mt-2">About us</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
